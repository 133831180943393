import { render, staticRenderFns } from "./QuizHindi.vue?vue&type=template&id=15e4d59a&"
import script from "./QuizHindi.vue?vue&type=script&lang=js&"
export * from "./QuizHindi.vue?vue&type=script&lang=js&"
import style0 from "./QuizHindi.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDialog,VProgressLinear,VRadioGroup,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
